
.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-8 {
  flex: 8;
}

.flex-10 {
  flex: 10;
}

.flex-1-1 {
  flex: 1 1 auto;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.row-reverse {
  flex-direction: row-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.space-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-20 {
  gap: 20px;
}

.gap-25 {
  gap: 25px;
}

.gap-50 {
  gap: 50px;
}

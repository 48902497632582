@import "area-theme";
@import "classes/flex";
@import "classes/grid";

.text2 {
  color: $textColor2;
}

.hd-1 {
  color: $headerColor;
}

.hd-2 {
  color: $headerColor2;
}

.bg {
  background-color: $backgroundColor;
}

.bg-2 {
  background-color: $backgroundColor2;
}

.info {
  color: $infoColor;
}

.warning {
  color: $warningColor;
}

.error {
  color: $errorColor;
}

.info-bg {
  background-color: $infoColor;
}

.warning-bg {
  background-color: $warningColor;
}

.error-bg {
  background-color: $errorColor;
}

.border-bottom-1 {
  border-bottom: 1px solid $accentColor;
}

.border-right-1 {
  border-right: 1px solid $accentColor;
}

.hover-primary {
  :hover {
    color: $textColorHighlight;
  }
}

.rounded-5 {
  border-radius: 5px;
}

.rounded-10 {
  border-radius: 10px;
}

.rounded-15 {
  border-radius: 15px;
}

.padding-0 {
  padding: 0px;
}

.padding-5 {
  padding: 5px;
}

.padding-10 {
  padding: 10px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-left-20 {
  padding-left: 20px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.w-full {
  width: 100%;
}

.w-60 {
  width: 60%;
}

.w-40 {
  width: 40%;
}

.h-full {
  height: 100%;
}

.justify-center {
  justify-content: center !important;
}

.justify-end {
  justify-content: end !important;
}

.pointer {
  :hover {
    cursor: pointer;
  }
}

.font-important {
  font-size: 20px;
  font-weight: bold;
}

.font-bg {
  font-size: 1.25rem !important;
}

.font-hd {
  font-size: 1.5rem !important;
}

.bg-transparent {
  background-color: transparent;
}

.table-header {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.nowrap {
  white-space: nowrap;
}

.table-header-xs {
  @extends: .table-header;
  width: 5%;
}

.table-header-sm {
  @extends: .table-header;
  width: 7.5%;
}

.table-header-md {
  @extends: .table-header;
  width: 10%;
}

.table-header-lg {
  @extends: .table-header;
  width: 15%;
}

.table-header-xl {
  @extends: .table-header;
  width: 25%;
}

.table-header-actions {
  @extends: .table-header;
  width: 10%;
}

.divider {
  width: 1px;
}

.v-divider {
  height: 1px;
  width: 100%;
  background-color: $accentColor;
}

.disabled {
  opacity: 0.5;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.selected {
  background-color: $accentColor;
  color: $textColor;
}

.hidden {
  visibility: hidden;
}

.min-content {
  width: min-content;
}

.text-right {
  text-align: right;
}

.has-violations-today {
  background-color: $backgroundColor;
  color: $backgroundColor2 !important;
}

.icon-32 {
  width: 32px !important;
  height: 32px !important;
}

.color-inherit {
  color: inherit;
}

.wb {
  word-break: break-word;
}

.table-filter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.icon-fix {
  .pi {
    min-height: 16px;
    min-width: 16px;
  }
}



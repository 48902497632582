//$headerColor: #DC2261; // pink
$headerColor: #a03033;
$headerColor2: #fff;

$textColor: #fff;
$textColor2: #000;
$textColorHighlight: #a03033;

$backgroundColor: #122E47;
$backgroundColor2: #061529;
$backgroundColor3: #061D29;

$accentColor: #424b57;
$infoColor: #1ea97c;
$warningColor: #FFC107;
$errorColor: #FF5252;

:root {
  --border-radius: 3px;
}

@font-face {
  font-family: 'Capsmall';
  src: url('/assets/Capsmall.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'CapsmallClean';
  src: url('/assets/Capsmall_clean.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'ACES-Futurista';
  src: url('/assets/ACES_Futurista-Regular_2.ttf') format('truetype'); /* Safari, Android, iOS */
}

//* {
//  scrollbar-color: $textColorHighlight transparent;
//}

/* Track */
::-webkit-scrollbar-track {
  background-color: $backgroundColor;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $backgroundColor2;
  border: 3px solid $backgroundColor;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $textColorHighlight;
}

body {
  background-color: $backgroundColor2;
}

body,
body .p-component,
.p-inputtext,
div,
span:not(.pi),
button:not(.checkin-btn),
input {
  font-size: 1rem;
  font-family: 'ACES-Futurista', serif !important;
}

h1,
h1 *{
  color: $textColorHighlight;
  font-family: 'CapsmallClean', serif !important;
  font-weight: 400;
}

h1,
h1 * {
  font-size: 1.5rem !important;
}

h2,
h2 * {
  font-size: 1.25rem !important;
}

.custom-spinner {
  .p-progress-spinner-circle {
    stroke: $textColorHighlight !important;
  }
}

.app {
  .component {
    background-color: $backgroundColor;
  }
}

.btn {
  .p-button {
    color: $textColor;
    background: $headerColor;
    border: 1px solid $headerColor;

    &:focus {
      box-shadow: none !important;
    }

    &:hover {
      background: transparent;
    }
  }
}

.icon-btn {
  background-color: transparent;

  .p-button {
    color: $textColor;
    background: transparent;

    &:hover {
      color: $textColorHighlight;
    }
  }
}

.text-btn {
  background-color: transparent !important;

  &:hover {
    background-color: transparent !important;
  }
}

.icon-btn-without-hover {
  &:hover {
    background-color: transparent !important;
  }
}

.icon-btn {
  &:hover {
    background-color: transparent !important;
  }
}

.danger-btn,
.danger-icon-btn {
  background-color: transparent !important;

  &:hover {
    color: $textColorHighlight;
    background-color: transparent !important;
  }
}

.hidden-columns-select {
  .active-while-panel-open {
    .p-button {
      color: $textColorHighlight;
    }
  }
}

.p-multiselect,
.p-paginator,
.p-dropdown,
input {
  background-color: $backgroundColor;
}

.p-inputtext:enabled:focus,
.p-inputtext:enabled:hover,
.p-multiselect:not(.p-disabled):hover,
.p-multiselect:not(.p-disabled).p-focus,
.p-dropdown:not(.p-disabled):hover,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus,
.p-dropdown:not(.p-disabled).p-focus {
  border-color: $textColorHighlight;
  box-shadow: none !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: $textColorHighlight;
  background: $textColorHighlight;
  box-shadow: none !important;
}

p-checkbox,
p-triStateCheckbox {
  .p-checkbox {
    .p-checkbox-box {
      background-color: $backgroundColor;
    }

    .p-checkbox-box.p-highlight {
      border-color: $textColorHighlight;
      background-color: $textColorHighlight;
    }
  }
}

p-inputSwitch {
  .p-inputswitch {
    &.p-focus .p-inputswitch-slider {
      box-shadow: none !important
    }

    .p-inputswitch-slider {
      background-color: $headerColor;

      &.p-highlight {
        border-color: $textColorHighlight;
        background: $textColorHighlight;
        box-shadow: none !important;
      }
    }


    &.p-inputswitch-checked {
      .p-inputswitch-slider {
        background-color: $headerColor;

        &:before {
          background-color: $textColor;
        }
      }
    }
  }

}

p-panel-menu {
  .p-panelmenu {
    .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text,
    .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
      color: $textColorHighlight;
    }


    .p-panelmenu-header-content {
      &:hover {
        .p-panelmenu-header-action {
          color: $textColorHighlight;
        }
      }
    }
  }
}

p-dropdown {
  .p-dropdown-panel {
    background-color: $backgroundColor2;
  }
}

p-multiselect {
  .p-multiselect-panel,
  .p-multiselect-panel .p-multiselect-header {
    background-color: $backgroundColor2;
  }
}

p-table {
  .p-datatable {
    .p-datatable-header,
    .p-datatable-footer,
    .p-datatable-thead > tr > th,
    .p-datatable-tbody > tr {
      background-color: $backgroundColor;
    }

    .p-sortable-column.p-highlight,
    .p-sortable-column:not(.p-highlight):hover {
      background-color: transparent !important;
    }

    .p-sortable-column:not(.p-highlight):hover,
    .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
      color: $textColorHighlight;
    }
  }
}

p-sidebar {
  .p-sidebar {
    background-color: $backgroundColor;
  }
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background-color: $headerColor;
  color: $textColor;
}

.p-dialog {
  .p-dialog-header {
    background-color: $backgroundColor;
  }

  .p-dialog-content {
    background-color: $backgroundColor;
    padding-top: 1rem;
  }

  .p-dialog-footer {
    background-color: $backgroundColor;
  }
}

.p-badge {
  background: $headerColor;
  color: $textColor;
}

p-selectbutton {
  .p-selectbutton {
    .p-button.p-highlight {
      background: $headerColor;
      border-color: $headerColor;
      color: $textColor;
    }

    .p-button {
      background-color: $backgroundColor;
      color: $textColor;

      &:focus {
        box-shadow: none !important;
      }
    }
  }
}

p-breadcrumb {
  .p-breadcrumb {
    background: transparent !important;
    border: none !important;
    padding: 0 !important;
  }
}

p-rating {
  .p-rating .p-rating-item.p-rating-item-active .p-rating-icon,
  .p-rating:not(.p-disabled):not(.p-readonly) .p-rating-item:hover .p-rating-icon {
    color: $headerColor;
  }
}

p-calendar {
  span > button {
    color: $textColor;
    background-color: $headerColor !important;
    border: 1px solid $headerColor !important;

    &:focus {
      box-shadow: none !important;
    }
  }

  span > input {
    &:focus {
      box-shadow: none !important;
    }
  }

  .p-calendar {
    .p-datepicker:not(.p-datepicker-inline) {
      background-color: $backgroundColor2 !important;
    }

    .p-datepicker {
      color: $textColor !important;

      .p-datepicker-header {
        color: $headerColor !important;
        background-color: $backgroundColor2 !important;

        .p-datepicker-title .p-datepicker-year,
        .p-datepicker-title .p-datepicker-month,
        .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
          color: $headerColor !important;

          &:hover {
            color: $headerColor !important;
          }

          &:focus {
            box-shadow: none !important;
          }
        }
      }
    }

    table td {
      padding: 0;

      > span {
        color: $textColor !important;

        &:hover {
          color: $headerColor !important;
          background-color: $backgroundColor2 !important;
        }

        &:focus {
          box-shadow: none !important;
        }
      }
    }

    table td.p-datepicker-today > span {
      color: $headerColor !important;
      background-color: $backgroundColor2 !important;
    }

    table td > span.p-highlight {
      color: $headerColor !important;
      background-color: $backgroundColor2 !important;
    }

    .p-yearpicker .p-yearpicker-year,
    .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight) {
      color: $textColor !important;
      background-color: $backgroundColor !important;

      &:hover {
        color: $headerColor !important;
      }

      &:focus {
        box-shadow: none !important;
      }
    }
  }
}

.chip {
  p-chip {
    .p-chip {
      min-width: 50px;
      justify-content: center;
      align-items: center;

      &:hover {
        cursor: pointer;
        background-color: $textColorHighlight;
      }
    }
  }

}

.chip-active {
  @extend .chip;

  p-chip {
    .p-chip {
      background-color: $textColorHighlight !important;
    }
  }
}



.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  align-items: center;
}

.grid-item.label {
  font-weight: bold;
}

.grid-item.value {
  text-align: left;
}

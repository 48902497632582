@media only screen and (max-width: 960px) {
  .table-caption {
    flex-direction: column !important;
  }

  .table-caption-table-info {
    flex-direction: column !important;
  }

  .table-caption-btn-wrapper {
    align-items: center !important;
    justify-content: center !important;
  }

  .btn-wrapper,
  .form-input {
    flex-direction: column;
  }

  .btn-wrapper {
    gap: 5px;
  }

  .active-game-day-dialog {
    width: 80vw !important;

    .p-dialog-header,
    .p-dialog-content,
    .p-dialog-footer {
      padding: 5px;
    }

  }
}

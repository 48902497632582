@media only screen and (max-width: 600px) {
  header {
    display: flex;
    flex-direction: column;
  }

  footer {
    .backend-version,
    .frontend-version,
    .divider {
      display: none;
    }
  }

  .page-header {
    flex-direction: column;

    app-gameday-info {
      justify-content: center;
    }
  }

  .table-caption {
    flex-direction: column !important;
    gap: 5px;
  }

  .table-caption-table-info {
    flex-direction: column !important;
  }

  .table-caption-btn-wrapper {
    align-items: center !important;
    justify-content: center !important;
  }

  .table-header-text {
    word-break: break-word;
  }

  .table-header-xs {
    @extends: .table-header;
    width: 2%;
  }

  .table-header-sm {
    @extends: .table-header;
    width: 5%;
  }

  .table-header-md {
    @extends: .table-header;
    width: 7.5%;
  }

  .table-header-lg {
    @extends: .table-header;
    width: 10%;
  }

  .table-header-xl {
    @extends: .table-header;
    width: 15%;
  }

  .table-header-actions {
    @extends: .table-header;
    width: 5%;
  }

  .btn-wrapper,
  .form-input {
    flex-direction: column;
  }

  .btn-wrapper {
    gap: 5px;
  }

  .form-input {
    label,
    input,
    p-dropdown,
    p-multiSelect,
    p-calendar {
      width: 100%;
    }
  }

  .game-day-header {
    flex-direction: column;
  }

  app-gameday-info {
    .game-day-info {
      flex-wrap: wrap;
      align-items: start;
    }

    .present-players {
      text-align: center;
    }

    .squads {
      flex-wrap: wrap;
    }
  }

  .checkin-info {
    flex-wrap: wrap;
  }

  .active-game-day-dialog {
    width: 90vw !important;
  }

  .checkin-step {
    font-size: 2rem !important;
  }

  .btn-wrapper-simplified {
    flex-direction: row;
    gap: 5px;
  }
}
